import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, shareReplay, take, throwError } from 'rxjs';
import { Page } from '../../../@common/entities/page.interface';
import { ApiPath } from '../../../@common/http/api-path.enum';
import { Logger } from '../../../@common/log/logger';
import { Author } from '../entities/author.interface';

@Injectable({
    providedIn: 'root',
})
export class AuthorService {
    private http = inject(HttpClient);
    private logger = inject(Logger);

    public getAuthors(): Observable<Page<Author>> {
        return this.http.get<Page<Author>>(`${ ApiPath.authors }/?limit=99999&offset=0`)
            .pipe(
                shareReplay(1)
            );
    }

    public getAuthor(slug: string): Observable<Author> {
        return this.http.get<Author>(`/v2/${ ApiPath.authors }/${ slug }/`, { withCredentials: true })
            .pipe(
                shareReplay(1),
                catchError(e => {
                    this.logger.apiError('Get author failed', e);
                    return throwError(() => e);
                })
            );
    }

    public postAuthor(authorData: {
        slug: string,
        name: string,
        description: string,
        image: string
    }): Observable<Author> {
        return this.http.post<Author>(ApiPath.authors + '/', authorData, { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Update author failed', e);
                    return throwError(() => e);
                })
            );
    }

    public getFollowedAuthors(limit: number, offset: number): Observable<any> {
        return this.http.get<any>(`${ ApiPath.follow }/?limit=${ limit }&offset=${ offset }`, { withCredentials: true })
            .pipe(
                shareReplay(1),
                catchError(e => {
                    this.logger.apiError('Get followed authors failed', e);
                    return throwError(() => e);
                })
            );
    }

    public followAuthor(id: number): void {
        this.http.post<any>(`${ ApiPath.followAdd }/${ id }/`, null, { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Follow author failed', e);
                    return throwError(() => e);
                })
            ).subscribe();
    }

    public unfollowAuthor(id: number): void {
        this.http.delete<any>(`${ ApiPath.followRemove }/${ id }/`, { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Unfollow author failed', e);
                    return throwError(() => e);
                })
            ).subscribe();
    }

}
